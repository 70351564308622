import { defineStore } from "pinia";
import axios from 'axios'
import { useRuntimeConfig } from "nuxt/app";
import {useUserStore} from '../user.store.ts'
import helper from "~/resources/js/libraries/helper";

export const useLocationStore = defineStore("location", {
	state: () => ({
		cities: []
	}),
    getters: {
		citiesGetter: (state) => () => state.cities,
	},
	actions: {
        getVcrmAccountToken() {
			if (!document) {
				return false;
			}
			const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
			if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
				return vue_cookie.split("=")[1];
			}
		},

        async fetchCities() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const userStore = useUserStore()

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            const country_id = userStore.userGetter()?.country_id;

            if (!country_id || Number.isNaN(country_id)) {
                return false
            }

            await axios.get(`${accountApiUrl}/lists/cities/${country_id}`)
                .then((res) => {
                    this.cities = res.data
                    return res.data
                })
                .catch(err => {
                    this.cities = []
                    return []
                })
        },

    }
});